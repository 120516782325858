<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logos/autolytic-blue.png')"
              max-height="auto"
              max-width="300px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">Willkommen bei Autolytic</p>
          <p class="mb-2">Bitte loggen Sie sich ein, um zu beginnen.</p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form ref="loginForm" v-model="valid" lazy-validation>
            <v-text-field
              v-model="credentials.email"
              outlined
              label="Email"
              placeholder=""
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="credentials.password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Passwort"
              placeholder=""
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox label="Login merken" hide-details class="me-3 mt-1"> </v-checkbox>

              <!-- forgot link -->
              <!-- <a href="javascript:void(0)" class="mt-1"> Passwort vergessen? </a> -->
            </div>

            <v-btn :disabled="!valid" :loading="isLoading" @click.prevent="validate" block color="primary" class="mt-6">
              Login
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <!--<v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            New on our platform?
          </span>
          <router-link :to="{name:'pages-register'}">
            Create an account
          </router-link>
        </v-card-text> -->

        <!-- divider -->
        <!--         <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text> -->

        <!-- social links -->
        <!--         <v-card-actions class="d-flex justify-center">
          <v-btn
            v-for="link in socialLink"
            :key="link.icon"
            icon
            class="ms-1"
          >
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/login-left-image.svg"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/login-right-image.svg"></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { mapActions, mapGetters } from 'vuex'

export default {
  data: () => ({
    dialog: true,
    tab: 0,
    tabs: [{ name: 'Login', icon: 'mdi-account' }],
    valid: true,
    isLoading: false,
    isPasswordVisible: false,
    credentials: {
      email: '',
      password: '',
    },
    icons: {
      mdiEyeOutline,
      mdiEyeOffOutline,
    },
    verify: '',
    emailRules: [v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
    show1: false,
    rules: {
      required: value => !!value || 'Required.',
      min: v => (v && v.length >= 8) || 'Min 8 characters',
    },
  }),
  computed: {
    passwordMatch() {
      return () => this.password === this.verify || 'Password must match'
    },
    //...mapGetters('auth', ['position']),
  },
  /*   watch: {
    position: function (value) {
      if (value.toUpperCase() == 'ADMIN' || value.toUpperCase() == 'PARTNER') {
        this.$router.push({ path: '/home' })
      } else {
        this.deleteToken()
        alert('Unauthorized')
      }
    },
  }, */
  methods: {
    //modulename, action
    ...mapActions('main', ['login', 'deleteToken']),
    validate() {
      this.isLoading = true
      if (this.$refs.loginForm.validate()) {
        this.startlogin()
      }
      this.isLoading = false
    },
    startlogin() {
        this.login(this.credentials).then(() => this.$router.push({ path: '/dashboard' }))
       .catch(err => console.log(err))
      },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
